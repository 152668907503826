import { getAuthTokenFromCache } from 'src/func/useAuthToken'

async function callFeraFunction(
  functionApp,
  functionName,
  params = {},
  auth_token = getAuthTokenFromCache(),
  method = 'POST',
) {
  const apiApp = 'xeno-handler'
  const apiEndpoint = 'fera_api'
  let url = `https://${apiApp}.azurewebsites.net/api/${apiEndpoint}`

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  // If for whatever reason a get is sent
  if (method === 'GET') {
    const queryParams = new URLSearchParams(params).toString()
    url += `?${queryParams}` // Change `&` to `?` to correctly append query parameters for GET requests
  } else {
    options.body = JSON.stringify(params)
  }

  // Ensure authentication token exists, and include it in header
  if (auth_token) {
    options.headers['Authorization'] = `Bearer ${auth_token}`
  }

  // Include the functionName and functionApp in the request body
  const bodyParams = {
    ...params,
    functionApp,
    functionName,
  }

  options.body = JSON.stringify(bodyParams)

  console.log('>Fera [FeraAPIFunctionCall]: Options ', options)

  try {
    console.log('>Fera [FeraAPIFunctionCall]: Sending request to:', url)
    console.log('>Fera [FeraAPIFunctionCall]: Request options:', JSON.stringify(options, null, 2))

    const response = await fetch(url, options)
    const responseData = await response.json()
    responseData.status = response.status

    console.log('>Fera [FeraFunctionCall]: Response status:', response.status)
    console.log('>Fera [FeraFunctionCall]: Response data:', JSON.stringify(responseData, null, 2))

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return responseData
  } catch (error) {
    console.error('>Fera [FeraAPIFunctionCall]: Error details:', {
      message: error.message,
      functionApp,
      functionName,
      params,
      url,
      method,
    })

    if (error.message === 'Failed to fetch') {
      console.error('>Fera [FeraAPIFunctionCall]: Network error. Please check:')
      console.error('1. Your internet connection')
      console.error('2. The API endpoint URL:', url)
      console.error('3. CORS settings on the server')
      console.error('4. Any network security rules or firewalls')
    }

    return { error: error.message, details: 'See console for more information' }
  }
}
export default callFeraFunction
