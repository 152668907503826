import React, { useEffect, useContext, useState, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import { withAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import getRoutes from '../getroutes'
import getUserRole from 'src/func/getUserRole'
import useUUID from 'src/func/useUUID'
import { UserContext } from 'src/App' // Adjust the import path as needed

//Note - To complete this, I need to set the conditional statement for routing to also skip all routes generation, to force unregistered
const AppContent = ({ auth0 }) => {
  const userRole = useContext(UserContext)
  const { isAuthenticated } = auth0
  const routes = getRoutes(isAuthenticated)
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            if (userRole === 'unregistered' && route.path !== '/register_invited') {
              return null
            }
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route
            path="*"
            element={
              <Navigate
                to={userRole === 'unregistered' ? '/register_invited' : '/manage'}
                replace
              />
            }
          />
        </Routes>
      </Suspense>
    </CContainer>
  )
}
AppContent.propTypes = {
  auth0: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
}

export default withAuth0(React.memo(AppContent))
