import React from 'react'

//Fera.bio
const Upload = React.lazy(() => import('./views/upload/Upload.js'))
const Manage = React.lazy(() => import('./views/manage/Manage.js'))
const Modify = React.lazy(() => import('./views/modify/Modify.js'))
const Basecall = React.lazy(() => import('src/views/basecall/Basecall.js'))
const CloudTasks = React.lazy(() => import('src/views/cloud_tasks/CloudTasks.js'))
const CloudResults = React.lazy(() => import('src/views/cloud_results/CloudResults.js'))
const Morph = React.lazy(() => import('./views/redirect/homelo'))
const Discover = React.lazy(() => import('./views/redirect/homelo'))
const Redirect = React.lazy(() => import('./views/register/Register.js'))
const ViewProfile = React.lazy(() => import('./views/profile/UserProfile.js'))
const AdminPanel = React.lazy(() => import('./views/developer/dev_panel.js'))
const Inspect = React.lazy(() => import('./views/redirect/homelo'))
const Register = React.lazy(() => import('./views/register/Register.js'))
const Invite = React.lazy(() => import('./views/invite/Invite.js'))
const Share = React.lazy(() => import('./views/share/Share.js'))

const getRoutes = (isAuthenticated) => {
  if (isAuthenticated) {
    return [
      // Define routes for authenticated users
      { path: '/', exact: true, name: 'Home' },
      { path: '/upload', name: 'Upload', element: Upload },
      { path: '/manage', name: 'Manage', element: Manage },
      { path: '/modify', name: 'Modify', element: Modify },
      { path: '/analysis/basecall', name: 'Basecall', element: Basecall },
      { path: '/analysis/cloudtasks', name: 'CloudTasks', element: CloudTasks },
      { path: '/analysis/cloudresults', name: 'CloudResults', element: CloudResults },
      { path: '/analysis/morph', name: 'Morph', element: Morph },
      { path: '/profile/viewprofile', name: 'User Profile', element: ViewProfile },
      { path: '/admin_panel', name: 'Panel', element: AdminPanel },
      { path: '/register_invited', name: 'Register', element: Register },
      { path: '/invite', name: 'Invite', element: Invite },
      { path: '/analysis/cloudtasks', name: 'CloudTasks', element: CloudTasks },
      { path: '/analysis/cloudresults', name: 'CloudResults', element: CloudResults },
      { path: '/visualize/inspect', name: 'Inspect', element: Inspect },
      { path: '/share', name: 'Share', element: Share },
    ]
  } else {
    return [
      // Define routes for unauthenticated users
      { path: '/', exact: true, name: 'Home' },
      { path: '/upload', name: 'Upload', element: Redirect },
      { path: '/download', name: 'Download', element: Redirect },
      { path: '/modify', name: 'Modify', element: Redirect },
      { path: '/analysis', name: 'Analysis', element: Redirect, exact: true },
      { path: '/analysis/basecall', name: 'Basecall', element: Redirect },
      { path: '/analysis/redirect', name: 'Redirect', element: Redirect },
      { path: '/analysis/discover', name: 'Discover', element: Redirect }, // ... other unauthenticated routes ...
      { path: '/analysis/morph', name: 'Morph', element: Redirect }, // ... other unauthenticated routes ...
      { path: '/profile/viewprofile', name: 'User Profile', element: Redirect }, // ... other unauthenticated routes ...
      { path: '/admin_panel', name: 'Panel', element: Redirect },
      { path: '/analysis/cloudtasks', name: 'CloudTasks', element: Redirect },
      { path: '/analysis/cloudresults', name: 'CloudResults', element: Redirect },
      { path: '/visualize/inspect', name: 'Inspect', element: Redirect },
      { path: '/share', name: 'Share', element: Redirect },
    ]
  }
}

export default getRoutes
