//Lists navigation items to filter
const roleAccess = {
  admin: 'all',
  user: ['Developer Tools', 'Panel', 'Invite', 'Tests', 'Register', 'Settings', 'Billing'],
  free: [
    'Panel',
    'Billing',
    'Logos',
    'Inspect Reads',
    'Quality Metrics',
    'Cloud Tasks',
    'Discover',
    'Morph',
    'Cluster',
    'Visualize',
    'Basecall',
    'Developer Tools',
    'Invite',
    'Tests',
    'Register',
    'Settings',
  ],
  default: [
    'Panel',
    'Billing',
    'Logos',
    'Inspect Reads',
    'Quality Metrics',
    'Cloud Tasks',
    'Discover',
    'Morph',
    'Cluster',
    'Invite',
    'Visualize',
    'Basecall',
    'Developer Tools',
    'Upload',
    'Tests',
    'Register',
    'Settings',
  ],
}

export default roleAccess
