import React, { Component, Suspense, useState, useEffect } from 'react'
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import { useAuth0 } from '@auth0/auth0-react'
import getUserRole from 'src/func/getUserRole'
import useUUID from 'src/func/useUUID'
import PropTypes from 'prop-types'
import getRoutes from './getroutes' // Import getRoutes

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
export const UserContext = React.createContext(null)

const App = ({ isLoading }) => {
  const { isAuthenticated } = useAuth0()
  const uuid = useUUID()
  const [userRole, setUserRole] = useState('free')

  useEffect(() => {
    async function fetchUserRole() {
      if (isAuthenticated && uuid) {
        const role = await getUserRole(uuid)
        console.log('response from feraapi for role check', role)
        if (role) {
          console.log('App user role changed', role)
          setUserRole(role)
        } else {
          setUserRole('unregistered') // Set userRole to 'unregistered' if role is not found
        }
      }
    }
    fetchUserRole()
  }, [isAuthenticated, uuid])

  return (
    <UserContext.Provider value={userRole}>
      <BrowserRouter basename={'/'}>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/*" element={<DefaultLayout userRole={userRole} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

App.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export default App
//              <Route path="register_invited" name="Register Invited" element={<Redirect />} />
//              <Route path="*" name="Home" element={<Navigate to="/" replace />} />
// <Route path="*" name="Home" element={<Navigate to="/" replace />} />
