import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const LogoutButton = () => {
  const { logout } = useAuth0()

  return (
    <button
      style={{
        marginLeft: '40px',
        fontWeight: 'bold',
        transition: 'opacity 0.3s',
        textDecoration: 'none', // Remove underline
        fontFamily: 'inherit', // Use the same font as the rest of the website
        color: '#06304d99', // Set the color (slightly more faded)
        fontSize: '0.9em', // Make the text smaller
        background: 'none', // Remove default button background
        border: 'none', // Remove default button border
        cursor: 'pointer', // Change cursor to pointer
      }}
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/' } })}
      onMouseOver={(e) => (e.currentTarget.style.opacity = '0.6')}
      onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
    >
      Log Out
    </button>
  )
}

export default LogoutButton
