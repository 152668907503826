import 'react-app-polyfill/stable'
import 'core-js'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import useAuthToken from 'src/func/useAuthToken'
import { BrowserRouter as Router, Navigate } from 'react-router-dom'
import DefaultLayout from './layout/DefaultLayout' // Import DefaultLayout

const onRedirectCallback = async (appState) => {
  // Retrieve user info from Auth0 response
  try {
    const user = {
      username: appState.user.name,
      email: appState.user.email,
      provider: appState.user.sub.split('|')[0],
      uuid: appState.user.sub.split('|')[1],
      created_at: new Date().toISOString(),
    }

    console.log('>Fera [FAuth] - Retrieving profile information...')
    // const response = await callFeraFunction('xeno-sql', 'check_user_exists', { uuid: user.uuid })
    //Previously - note - had create_new_user and passed {user}
    /*
    if (response.error) {
      console.log('Fera [redirect call] - Failed to retrieve user profile.', user)
      throw new Error(response.error)
    }
    */
    console.log('>Fera [HandleAuthenticationHandshake] - User logged in and synced.')
  } catch (error) {
    console.error('Error creating user:', error.message)
  }
}

const AppWrapper = () => {
  const { isAuthenticated, user, isLoading, loginWithRedirect } = useAuth0()
  const token = useAuthToken()

  useEffect(() => {
    const handleAuth = async () => {
      if (isAuthenticated && user && token) {
        const appState = {
          user,
          returnTo: window.location.origin,
        }
        await onRedirectCallback(appState)
      } else if (!isLoading && !isAuthenticated) {
        loginWithRedirect()
      }
    }

    handleAuth()
  }, [isAuthenticated, user, token, isLoading, loginWithRedirect])

  //Note this is importnat for ensuring that session cookies dont overlap...
  if (isLoading || (isAuthenticated && !token)) {
    return null
  }

  return isAuthenticated ? <App isLoading={isLoading} /> : null
}

const root = createRoot(document.getElementById('root'))
root.render(
  <div className="root-container">
    <React.Fragment>
      <Provider store={store}>
        <Auth0Provider
          domain="ferabio.us.auth0.com"
          clientId="M3dzLt7QpHM6Zdi4AuG9XklrzZ51YMcC"
          authorizationParams={{
            audience: 'https://ferabio.us.auth0.com/api/v2/',
            redirect_uri: window.location.origin,
          }}
        >
          <AppWrapper />
        </Auth0Provider>
      </Provider>
      {reportWebVitals()}
    </React.Fragment>
  </div>,
)
