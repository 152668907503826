import { useAuth0 } from '@auth0/auth0-react'

const useUUID = () => {
  const { isAuthenticated, user } = useAuth0()

  if (isAuthenticated && user) {
    return user.sub.split('|')[1]
  }

  return null // Return null or an appropriate value when not authenticated
}

export default useUUID
