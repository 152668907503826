import React from 'react'
import { useLocation } from 'react-router-dom'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import RegisterSidebar from 'src/views/register/registerSidebar'

const DefaultLayout = () => {
  const location = useLocation()
  const isRegistrationRoute = location.pathname === '/register_invited'

  return (
    <div>
      {isRegistrationRoute ? <RegisterSidebar /> : <AppSidebar />}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-5">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default DefaultLayout
