import React, { useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import xnabrand from 'src/assets/xnabrand/feracloud_logo_text.png'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { Link } from 'react-router-dom' // Import Link
import { useAuth0 } from '@auth0/auth0-react' // Import useAuth0

// sidebar nav config
import getNavItems from '../getNavItems'
import { UserContext } from '../App' // Adjust the import path as needed

const AppSidebar = () => {
  const { logout } = useAuth0() // Get the logout function
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const userRole = useContext(UserContext)

  const navItems = useMemo(() => getNavItems(userRole, logout), [userRole, logout])

  return (
    <CSidebar position="fixed" unfoldable={unfoldable} visible={sidebarShow}>
      <CSidebarBrand className="d-none d-md-flex">
        <Link to="/manage">
          <img className="sidebar-brand-full" src={xnabrand} height={35} />
        </Link>
      </CSidebarBrand>
      <CSidebarNav>
        {' '}
        {/* Add padding here */}
        <div className="navbar-small-container">
          <SimpleBar style={{ paddingBottom: '400px !important' }}>
            <AppSidebarNav items={navItems} />
          </SimpleBar>
        </div>
      </CSidebarNav>
      <div className="d-none d-lg-flex" />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
