import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'

const useAuthToken = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated && !isLoading) {
        try {
          // Remove old token from localStorage
          localStorage.removeItem('authToken')

          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: 'https://ferabio.us.auth0.com/api/v2/',
              prompt: 'consent', // Force re-consent
            },
            ignoreCache: true, // Force token refresh
          })
          setToken(token)
          localStorage.setItem('authToken', token)
        } catch (error) {
          console.error('>Fera Auth - Error fetching token:', error)
        }
      }
    }

    fetchToken()
  }, [getAccessTokenSilently, isAuthenticated, isLoading])

  return token
}

export default useAuthToken

export const getAuthTokenFromCache = () => {
  return localStorage.getItem('authToken')
}
