//Dedicated function call for getting user storage information. Important for uploading data to Fera Cloud.
import callFeraFunction from 'src/func/feraAPI'

const getUserRole = async (uuid) => {
  try {
    const response = await callFeraFunction('xeno-sql', 'get_role_by_uuid', {
      uuid: uuid,
    })
    console.log('>Fera [fetchUserRole]: Success', response.role_name)
    return response.role_name
  } catch (error) {
    console.error('>Fera [fetchUserRole]: Error fetching user role:', error)
    return null
  }
}

export default getUserRole
